import { dashboard, type queryRun, type visualization } from "@fscrypto/domain";
import type { DashboardView } from "@fscrypto/domain/dashboard";
import { Tooltip } from "@fscrypto/ui";
import { Badge } from "@fscrypto/ui/badge";
import { VisualizationRenderer } from "@fscrypto/viz-2";
import clsx from "clsx";
import deepEqual from "fast-deep-equal";
import { Code2Icon, CodeXmlIcon, CopyIcon, RefreshCwIcon } from "lucide-react";
import { memo, useState } from "react";
import { merge } from "remeda";
import { $path } from "remix-routes";
import MarketingAuthModal from "~/features/app-shell/marketing/marketing-auth-modal";
import { useCurrentUser } from "~/features/current-user";
import { useTableState } from "~/features/studio-2/query/state/query-table-state";
import { QueryResults } from "~/features/studio-2/query/ui/query-results";
import { LastRunDate } from "~/features/studio-2/query/ui/query-toolbar/query-toolbar";
import { useVisualizationAnyVersion, useVisualizationConfigBuilder } from "~/features/studio-2/visualization/hooks";
import { DynamicIcon } from "~/shared/components/dynamic-icon";
import { useDashboardCellContent } from "../../../hooks/useDashboardCellContent";
import { useDashboardCellStyles } from "../../../hooks/useDashboardCellStyles";
import { useRootCellContent } from "../../../hooks/useRootCellContent";
import { useQueryRunResultFromCell } from "../../../hooks/visualization-cells/useQueryRunResultFromCell";
import { useVisualizationCell } from "../../../hooks/visualization-cells/useVisualizationCell";
import { ActiveCellListener } from "../../active-cell/active-cell-listener";
import { BackgroundContainer, BorderContainer, Description, Title } from "../../content-elements/content-elements";
import { LegacyVisualizationContainer } from "./legacy-visualization-container";
import { useQuery } from "~/features/studio-2/query/state/query";
import { useDashboardSettings } from "../../../hooks/useDashboardSettings";

/**
 * Renders the content of a visualization cell in the dashboard.
 * @param {Object} props - Component props
 * @param {string} props.cellId - Unique identifier for the cell
 * @param {string} props.dashboardId - Unique identifier for the dashboard
 * @param {DashboardView} props.dashboardView - Current view mode of the dashboard
 * @param {string} props.layoutId - Identifier for the layout
 */
export const VisualizationCellContent = ({
  cellId,
  dashboardId,
  dashboardView,
  layoutId,
  isMobile,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  layoutId: string;
  isMobile: boolean;
}) => {
  const { currentUser } = useCurrentUser();
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const { visId, queryId, canRender, runQuery, cloneViz } = useVisualizationCell(cellId, dashboardId, dashboardView);
  const onOpenAuthModalChange = (isOpen: boolean) => {
    if (!isOpen) {
      setOpenAuthModal(false);
    }
  };
  if (!canRender) return null;
  return (
    <ActiveCellListener
      cellId={cellId}
      dashboardId={dashboardId}
      dashboardView={dashboardView}
      customOptions={
        <>
          <EditQueryLink queryId={queryId} visId={visId} dashboardId={dashboardId} />
          <Tooltip content="Refresh Query" side="top">
            <RefreshCwIcon
              className="size-4"
              onClick={() => {
                if (currentUser) {
                  runQuery?.();
                } else {
                  setOpenAuthModal(true);
                }
              }}
            />
          </Tooltip>
          {dashboardView === "draft" && (
            <Tooltip content="Clone Visualization" side="top">
              <CopyIcon
                className="size-4"
                onClick={() => {
                  if (currentUser) {
                    cloneViz?.();
                  } else {
                    setOpenAuthModal(true);
                  }
                }}
              />
            </Tooltip>
          )}
        </>
      }
      isMobile={isMobile}
    >
      <BorderContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
        <BackgroundContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
          <div className={clsx("flex h-full flex-1 flex-col")}>
            <VisualizationVersionWrapper
              visId={visId}
              queryId={queryId}
              cellId={cellId}
              dashboardId={dashboardId}
              dashboardView={dashboardView}
              layoutId={layoutId}
            />
          </div>
        </BackgroundContainer>
      </BorderContainer>
      <MarketingAuthModal
        open={openAuthModal}
        redirectBackOnClose={false}
        defaultOpen={false}
        onOpenChange={onOpenAuthModalChange}
        persona="viewer"
        origin="dashboard-refresh"
      />
    </ActiveCellListener>
  );
};
const EditQueryLink = ({ queryId, visId, dashboardId }: { queryId: string; visId: string; dashboardId: string }) => {
  const vis = useVisualizationAnyVersion(visId);
  const queryState = useQuery(queryId);
  const { owner } = useDashboardSettings(dashboardId);
  const { currentUser } = useCurrentUser();
  const isOwner = currentUser?.currentProfileId === owner?.id;

  if (!vis) return null;

  const href = generateQueryHref({
    queryId,
    version: vis.value.version,
    vizId: visId,
    isOwner,
    ownerSlug: owner?.slug ?? "",
    latestSlug: queryState?.query.latestSlug ?? "",
    slugId: queryState?.query.slugId ?? "",
  });

  return (
    <Tooltip content="Edit Query" side="top">
      <a href={href} target="_blank" rel="noreferrer">
        <CodeXmlIcon className="size-4" />
      </a>
    </Tooltip>
  );
};

interface VisualizationContainerProps {
  visId: string;
  queryId: string;
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  layoutId: string;
}

/**
 * Wrapper component to handle different versions of visualizations.
 * @param {VisualizationContainerProps} props - Component props
 */
export const VisualizationVersionWrapper = (props: VisualizationContainerProps) => {
  const vis = useVisualizationAnyVersion(props.visId);
  if (!vis) return null;
  if (vis.value.version === "3") {
    return <VisualizationContainer {...props} />;
  }
  return <LegacyVisualizationContainer {...props} />;
};

/**
 * Main container for rendering visualizations.
 * Memoized to optimize performance.
 */
export const VisualizationContainer = memo(
  ({ visId, queryId, cellId, dashboardId, dashboardView }: VisualizationContainerProps) => {
    const { run } = useQueryRunResultFromCell({
      queryId,
      dashboardId,
      cellId,
      dashboardView,
    });
    const { theme } = useRootCellContent(dashboardId, dashboardView);

    if (!run) return null;

    if (run?.status !== "finished" || !run?.resultsRetrieved) {
      return <QueryResults queryId={queryId} run={run} />;
    }
    return (
      <div
        className={clsx("flex h-full w-full flex-col", {
          light: theme === "light",
          dark: theme === "dark",
        })}
      >
        <VizTitleDescription
          visId={visId}
          latestRun={run}
          cellId={cellId}
          dashboardId={dashboardId}
          dashboardView={dashboardView}
        />
        <div className="size-full flex flex-1 overflow-hidden bg-transparent py-1">
          {run && <MemoizedVisualization visId={visId} latestRun={run} dark={theme === "dark"} />}
        </div>
        <VisualizationFooter cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.visId === nextProps.visId && prevProps.queryId === nextProps.queryId;
  },
);

VisualizationContainer.displayName = "VisualizationContainer";

interface VisualizationProps {
  visId: string;
  latestRun: queryRun.QueryRunResult;
  dark?: boolean;
}
/**
 * Renders the visualization based on the provided configuration and data.
 * @param {VisualizationProps} props - Component props
 */
const Visualization = ({ visId, latestRun, dark }: VisualizationProps) => {
  const tableProps = useTableState(`query-${visId}`);
  const config = useVisualizationConfigBuilder(visId, latestRun);
  if (!config.inputs) return null;
  const filteredOptions = merge(config.options, {
    title: null,
    subtitle: null,
  });
  return (
    <VisualizationRenderer
      input={config.inputs}
      // the title and subtitle are nullified for styling purposes. we are asserting here so we still get the type safety
      // from the schema at dev time without having to add nullable.
      options={filteredOptions as unknown as visualization.v3.Options}
      dark={dark}
      tableProps={{ ...tableProps, tableId: `query-${visId}` }}
      startedAt={latestRun.startedAt}
      endedAt={latestRun.endedAt}
    />
  );
};

/**
 * Memoized version of the Visualization component for performance optimization.
 */
export const MemoizedVisualization = memo(Visualization, (prevProps, nextProps) => {
  return deepEqual(prevProps, nextProps);
});

/**
 * Renders the title and description for a visualization cell.
 * @param {Object} props - Component props
 * @param {string} props.cellId - Unique identifier for the cell
 * @param {string} props.visId - Unique identifier for the visualization
 * @param {string} props.dashboardId - Unique identifier for the dashboard
 * @param {DashboardView} props.dashboardView - Current view mode of the dashboard
 * @param {queryRun.QueryRunResult} props.latestRun - Latest query run result
 */
export const VizTitleDescription = ({
  cellId,
  dashboardId,
  dashboardView,
  visId,
  latestRun,
}: {
  cellId: string;
  visId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  latestRun: queryRun.QueryRunResult;
}) => {
  const config = useVisualizationConfigBuilder(visId, latestRun);
  const [cellStyle] = useDashboardCellStyles(cellId, dashboardId, "base", dashboardView);
  const [{ icon, iconColor }] = useDashboardCellContent(cellId, dashboardId, "visualization", dashboardView);
  const title = config.options?.title?.text;
  const description = config.options?.subtitle?.text;
  return (
    <div
      className={clsx("flex flex-col border-b p-2 w-full", {
        "items-center": cellStyle?.vertAlignKey === "center",
        "items-start": cellStyle?.vertAlignKey === "start",
        "items-end": cellStyle?.vertAlignKey === "end",
      })}
    >
      <div className="flex items-center gap-x-2 w-[calc(100%-80px)]">
        {icon && <DynamicIcon name={icon} className="size-5 display-inline" style={{ color: iconColor }} />}

        <Title titleSize={cellStyle?.titleSize ?? "lg"} titleColor={cellStyle?.titleColor} title={title} />
      </div>
      <Description descriptionColor={cellStyle?.descriptionColor} description={description} />
    </div>
  );
};

/**
 * Generates a URL for editing a query or viewing a public query.
 * @param {Object} params - The parameters for generating the URL
 * @param {string} params.queryId - Unique identifier for the query
 * @param {string} params.vizId - Unique identifier for the visualization
 * @param {string} params.version - Version of the visualization
 * @param {boolean} params.isOwner - Whether the current user is the owner of the query
 * @param {string} params.ownerSlug - Slug of the query owner
 * @param {string} params.slugId - Slug ID of the query
 * @param {string} params.latestSlug - Latest slug of the query
 * @returns {string} The generated URL
 */
const generateQueryHref = ({
  queryId,
  vizId,
  version,
  isOwner,
  ownerSlug,
  slugId,
  latestSlug,
}: {
  queryId: string;
  vizId: string;
  version: string;
  isOwner: boolean;
  ownerSlug: string;
  slugId: string;
  latestSlug: string;
}) => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  if (isOwner) {
    return `${baseUrl}${
      version === "2"
        ? $path("/studio/queries/:id/visualizations/v2/:vizId", { id: queryId, vizId })
        : $path("/studio/queries/:id/visualizations/:vizId", { id: queryId, vizId })
    }`;
  } else {
    return `${baseUrl}${$path("/:owner/q/:slugId/:slug", {
      owner: ownerSlug,
      slugId: slugId,
      slug: latestSlug,
    })}`;
  }
};

/**
 * Renders the footer of a visualization cell.
 * @param {Object} props - The component props
 * @param {string} props.cellId - The ID of the cell
 * @param {string} props.dashboardId - The ID of the dashboard
 * @param {DashboardView} props.dashboardView - The current view of the dashboard
 * @param {string} props.queryId - The ID of the query associated with the visualization
 * @param {queryRun.QueryRunResult} props.latestRun - The latest query run result
 */
export const VisualizationFooter = ({
  cellId,
  dashboardId,
  dashboardView,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
}) => {
  const { filteredQueryParams, queryId, layoutHasParamCells } = useVisualizationCell(
    cellId,
    dashboardId,
    dashboardView,
  );
  if (!layoutHasParamCells || filteredQueryParams?.length === 0) return null;
  return (
    <div className="flex justify-between items-center border-t p-2 text-xs">
      <div className="flex gap-x-2">
        {layoutHasParamCells &&
          filteredQueryParams?.map((param) => (
            <Badge key={param.id} variant="secondary">
              <Code2Icon className="size-4 mr-2" />
              {param.name}
            </Badge>
          ))}
      </div>
      {queryId && <LastRunDate queryId={queryId} />}
    </div>
  );
};
