import { CellLayout, DashboardView } from "@fscrypto/domain/dashboard";
import { dashboard as dashboardDomain } from "@fscrypto/domain/dashboard";
import { useEntity, useStoreWithDynamicSelector } from "@fscrypto/state-management";
import { dashboardFactory } from "../state/dashboard";
import { useDashboard } from "./useDashboard";

export const useDashboardCellLayout = (
  cellId: string,
  dashboardId: string,
  dashboardView: DashboardView,
  isMobile: boolean = false,
) => {
  const db = useEntity(dashboardFactory, dashboardId);
  // const config = dashboardView === "published" ? db?.publishedConfig : db?.draftConfig;
  const { entity } = useDashboard(dashboardId);
  const layout = useStoreWithDynamicSelector(db?.store, (s) =>
    dashboardView === "published"
      ? s.publishedConfig?.[isMobile ? "mobileLayouts" : "layouts"]?.[cellId]
      : s.draftConfig?.[isMobile ? "mobileLayouts" : "layouts"]?.[cellId],
  );
  const siblingLayouts = useStoreWithDynamicSelector(db?.store, (s) => {
    const config = dashboardView === "published" ? s.publishedConfig : s.draftConfig;
    if (!config) return [] as CellLayout[];
    const cell = dashboardDomain.findCellInfoById(cellId)(config);
    const parentId = cell?.parentCellId;
    if (!parentId) return [] as CellLayout[];

    // return (dashboardDomain.findLayoutsFromCellId(s, parentId, dashboardView) ?? []) as CellLayout[];
    return dashboardDomain.findChildLayoutsFromCellId(parentId, isMobile)(config);
  });

  const toggleLocked = () => {
    entity?.toggleCellLocked(cellId, !layout?.static);
  };
  return { isLocked: layout?.static, toggleLocked, layout, siblingLayouts };
};
