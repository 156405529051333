import { CellVariant, DashboardView } from "@fscrypto/domain/dashboard";
import { Popover, Tooltip } from "@fscrypto/ui";
import clsx from "clsx";
import { ArrowLeftRightIcon, GripVerticalIcon, LockIcon, LockOpenIcon, Trash2Icon } from "lucide-react";
import { ReactNode } from "react";
import { tracking } from "~/utils/tracking";
import { useDashboard } from "../../hooks/useDashboard";
import { useDashboardCell } from "../../hooks/useDashboardCell";
import { useDashboardCellLayout } from "../../hooks/useDashboardCellLayout";
import { useDashboardCellStyles } from "../../hooks/useDashboardCellStyles";
import { useDashboardEditor } from "../../hooks/useDashboardEditor";
import { LayoutTree } from "../old-tree/tree";

export const CellLabel = ({
  cellId,
  dashboardId,
  dashboardView,
  isActiveCell = false,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  isActive?: boolean;
  isActiveCell?: boolean;
}) => {
  const { cell } = useDashboardCell(cellId, dashboardId, dashboardView);
  return (
    <div className="absolute left-0 top-0 m-1 flex items-center gap-x-1">
      {/* {isActiveCell && (
        <DragHandle cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} variant={cell.variant} />
      )} */}
      <div className="bg-primary text-background rounded px-2 py-1 text-sm font-semibold">{cell.variant}</div>
    </div>
  );
};

export const CellOptions = ({
  cellId,
  dashboardId,
  dashboardView,
  children,
  isActiveCell = false,
  parentCellId,
  preventMove = false,
  preventLock = false,
}: {
  cellId: string;
  isLayout?: boolean;
  children?: ReactNode;
  dashboardId: string;
  dashboardView: DashboardView;
  isActiveCell?: boolean;
  parentCellId?: string;
  preventMove?: boolean;
  preventLock?: boolean;
}) => {
  const { removeDashboardCell } = useDashboard(dashboardId);
  const { setActiveLayoutId } = useDashboardEditor({ dashboardId });
  const { cell } = useDashboardCell(cellId, dashboardId, dashboardView);
  const { isLocked, toggleLocked } = useDashboardCellLayout(cellId, dashboardId, dashboardView);
  const [{ titleColor }] = useDashboardCellStyles(cellId, dashboardId, "base", dashboardView);

  if (!isActiveCell && !children) return null;
  const { appColor, color1 } = titleColor ?? {};
  return (
    <div
      onMouseDown={(e) => e.stopPropagation()}
      className={clsx("absolute right-0 top-0 m-1 flex h-8 cursor-pointer items-center gap-x-2 rounded px-2", {
        "bg-primary text-primary-foreground": dashboardView === "draft" && isActiveCell,
        "text-primary": appColor == "app-primary",
        "text-muted": appColor == "app-muted",
        "text-success": appColor == "app-success",
        "text-warning": appColor == "app-warning",
        "text-background": appColor == "app-bg",
        "text-foreground": !titleColor || appColor == "app-foreground",
      })}
      style={{ color: !appColor ? color1 : undefined }}
    >
      {children}
      {dashboardView == "draft" && isActiveCell && (
        <>
          {!preventMove && (
            <Tooltip content="Move to Layout" side="top">
              <div>
                <MoveDropdown cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} />
              </div>
            </Tooltip>
          )}
          {!preventLock && (
            <Tooltip content={isLocked ? "Unlock Cell" : "Lock Cell"} side="top">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  toggleLocked?.();
                }}
              >
                {isLocked ? <LockOpenIcon className="size-4" /> : <LockIcon className="size-4" />}
              </div>
            </Tooltip>
          )}
          <Tooltip content="Remove Cell" side="top">
            <div
              onClick={(e) => {
                e.stopPropagation();

                if (["text", "layout", "heading", "image", "tabs-panel"].includes(cell.variant)) {
                  tracking(`dashboard_editor_remove_${cell.variant}`, "Dashboard Beta");
                }

                removeDashboardCell?.(cellId);

                if (parentCellId) {
                  setActiveLayoutId(parentCellId);
                }
              }}
            >
              <Trash2Icon className="size-4" />
            </div>
          </Tooltip>
        </>
      )}
    </div>
  );
};

const MoveDropdown = ({
  cellId,
  dashboardId,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
}) => {
  const { moveDashboardCell } = useDashboard(dashboardId);
  const { setActiveLayoutId, setActiveCellId } = useDashboardEditor({ dashboardId });
  return (
    <Popover.Root>
      <Popover.Trigger
        onMouseDown={(e) => e.stopPropagation()}
        className="nullify-active-layout-listener flex h-full items-center"
      >
        <ArrowLeftRightIcon className="size-4" />
      </Popover.Trigger>
      <Popover.Content className="nullify-active-layout-listener w-72">
        <LayoutTree
          currentCellId={cellId}
          dashboardId={dashboardId}
          // rowContent={(cell, isHovered) => (
          //   <RowContent newCell={cell} isHovered={isHovered} dashboardId={dashboardId} cellId={cellId} />
          // )}
          onSelectCell={(newCell) => {
            if (newCell.variant === "tabs-panel") {
              return;
            }
            if (newCell.id === cellId) {
              alert("Can't move cell to itself");
              return;
            }
            moveDashboardCell?.(cellId, newCell.id);

            setActiveCellId(cellId);
            setActiveLayoutId(newCell.id, true);
          }}
        />
      </Popover.Content>
    </Popover.Root>
  );
};

export const DragHandle = ({
  variant,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  variant: CellVariant;
}) => {
  if (variant === "root" || variant === "header") return null;
  return (
    <div
      className="grid-draggable-handle border-primary bg-background flex h-7 items-center justify-center rounded border shadow-sm"
      style={{ cursor: "grab" }}
    >
      <GripVerticalIcon className="size-6 text-primary grid-draggable-handle" />
    </div>
  );
};
