import { DashboardView } from "@fscrypto/domain/dashboard";
import { QueryRunResult } from "@fscrypto/domain/query-run";
import { type Data } from "@fscrypto/viz";
import clsx from "clsx";
import {} from "observable-hooks";
import ResettableErrorBoundary from "~/features/errors/ResettableErrorBoundary";
import { QueryResults } from "~/features/studio-2/query/ui/query-results";
import { useV2Visualization } from "~/features/studio-2/visualization/hooks";
import { VisualizationDisplay } from "~/features/visualization/ui/charts";
import { useQueryRunResultFromCell } from "../../../hooks/visualization-cells/useQueryRunResultFromCell";
import { Title } from "../../content-elements/content-elements";

export const LegacyVisualizationContainer = ({
  visId,
  queryId,
  dashboardView,
  dashboardId,
  cellId,
}: {
  visId: string;
  queryId: string;
  cellId: string;
  dashboardView: DashboardView;
  dashboardId: string;
}) => {
  const { run } = useQueryRunResultFromCell({
    queryId,
    dashboardId,
    cellId,
    dashboardView,
  });
  if (!run) return null;

  if (run?.status !== "finished") {
    return <QueryResults queryId={queryId} run={run} />;
  }
  return (
    <div className="flex w-full flex-1 flex-col">
      <LegacyVizTitleDescription visId={visId} />
      <div className="size-full bg-background py-1">{run && <LegacyVisualization visId={visId} run={run} />}</div>
    </div>
  );
};

export const LegacyVisualization = ({
  visId,
  run,
}: {
  visId: string;
  run: QueryRunResult;
}) => {
  const viz = useV2Visualization(visId);
  if (!viz) return null;
  return (
    <ResettableErrorBoundary fallbackRender={({ error }) => <div>Unable to render chart.</div>} resetKeys={[visId]}>
      <VisualizationDisplay visualization={viz.value} data={(run?.jsonData ?? []) as Data[]} context="editor" />
    </ResettableErrorBoundary>
  );
};

export const LegacyVizTitleDescription = ({ visId }: { visId: string }) => {
  const viz = useV2Visualization(visId);
  return (
    <div className={clsx("flex flex-col border-b p-2")}>
      <div className="flex items-center gap-x-2">
        <Title title={viz?.value.title} />
      </div>
    </div>
  );
};
